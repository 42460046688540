import { captureException } from '@sentry/nextjs'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { getRemoteConfigValue, RemoteConfigKeys } from '../../../services/firebase/RemoteConfig'
import { Theme } from '../../../styles/theme-enum'
import { Button } from '../../atoms/Button'
import { LogoIcon } from '../../atoms/icons/LogoIcon'
import { SmallLogoIcon } from '../../atoms/icons/SmallLogoIcon'
import { KickstarterBanner } from '../KickstarterBanner'
import { Container, Header, HeaderLink, LogoLink, Row, Spacer } from './styles'

export const LandingPageHeader = (props: { onClickButton: Function }) => {
  const { t: translate } = useTranslation(LocaleNamespaces.HEADER)
  const { onClickButton } = props
  const router = useRouter()
  const [bannerText, setBannerText] = useState('COMING SOON ON KICKSTARTER')

  const getBannerInfos = async () => {
    try {
      const response : string = await getRemoteConfigValue<string>(RemoteConfigKeys.LPI_KICKSTARTER)
      if (response) {
        setBannerText(response)
      }
    } catch (e) {
      captureException(e)
    }
  }

  useEffect(() => {
    getBannerInfos()
  }, [])

  return (
    <>
      <Header>
        <Container>
          <Row data-testid="landing-page-row">
            <LogoLink href="/" data-testid="home-link">
              <span className="desktop">
                <LogoIcon />
              </span>
              <span className="mobile">
                <SmallLogoIcon/>
              </span>
            </LogoLink>

            <div style={{display: 'flex', alignItems: 'center'}}>
            
              <HeaderLink href="/about-us" data-testid="about-us-link" isActive={router.asPath == '/about-us'}>
                {translate('about-us')}
              </HeaderLink>

              <Button
                onClickFunction={onClickButton}
                theme={Theme.default}
                customWidth={{mobileValue:'115px', desktopValue:'299px'}}
                customHeight={{mobileValue: '25px', desktopValue: '41px'}}
                customPadding={{mobileValue: '5px 10px', desktopValue: '7px 65px'}}
              >
                {translate('notify-me-button')}
              </Button>

            </div>
          </Row>
        </Container>
        <KickstarterBanner text={bannerText} />
      </Header>
      <Spacer desktopHeight={bannerText ? 142 : 102} mobileHeight={bannerText ? 100 : 75} />
    </>
  )
}
