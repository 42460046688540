import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

const primaryColor = '#4E98F1'
const secondaryColor = '#222222'
const errorColor = '#E46969'
const errorBorderColor = '#D91818'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  
  ${media.tabMUp} {
    width: 100%;
    margin: -50px auto 50px;
  }
  
  @media only screen and (min-width: 990px) {
    margin: -20px auto 50px;
  }

  @media only screen and (min-width: 1110px) {
    margin: 0px auto 50px;
  }
`

export const InputField = styled.input<{ isValidEmail: boolean }>`
  margin: 0;
  padding: 4px 13px;
  outline: none;
  width: 100%;
  height: 44px;
  border-radius: 25px;
  border: ${props => (props.isValidEmail ? 'none' : `1px solid ${errorBorderColor}`)};
  color: ${props => (props.isValidEmail ? '#666' : `${errorColor}`)};
  font-size: 12px;
  margin-bottom: 8px;
  text-align: center;

  ${media.tabMUp} {
    padding: 12px 25px 11px 25px;
    border-radius: 42px;
    border: ${props => (props.isValidEmail ? 'none' : `2px solid ${errorBorderColor}`)};
    font-size: 16px;
    margin-bottom: 0;
    text-align: left;
  }
  
  ${media.deskGUp} {
    font-size: 20px;
  }

  ::placeholder, ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+  :: Edge */
    color: #BBB;
    font-size: 12px !important;
    opacity: 1; /* Firefox */
    text-align: center !important;

    ${media.tabMUp} {
      text-align: start;
      font-size: 16px !important;
    }

    ${media.deskGUp} {
      font-size: 20px !important;
    }
  }
  
  &:focus {
    border: ${props => (`1px solid ${props.isValidEmail ? primaryColor : errorBorderColor}`)};

    ${media.tabMUp} {
      border: ${props => (`2px solid ${props.isValidEmail ? primaryColor : errorBorderColor}`)};
    }
  }
`

export const InputButton = styled.input<{ isprimaryColor: boolean }>`
  width: 100%;
  height: 44px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  background-color: ${props => (props.isprimaryColor ? primaryColor : secondaryColor)};
  color: #F7F7F7;
  font-size: 12px;
  margin: 8px 0 16px;
  
  ${media.tabMUp} {
    font-size: 16px;    
    margin: 16px 0;
  }

  ${media.deskGUp} {
    margin: 16px 0 51px;
  }
`

export const InputError = styled.span`
  color: ${errorColor};
  font-size: 12px;
  width: 100%;
  padding-left: 25px;
  text-align: left;
  min-height: 25px;
  max-height: 25px;
  margin: 0;

  ${media.tabMUp} {
    font-size: 20px;
  }
`