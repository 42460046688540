import styled, { CSSProperties } from 'styled-components'

interface IImageProps {
  srcSet: string,
  src: string,
  alt: string,
  className?: string,
  loadingStrategy?: 'lazy' | 'eager'
  objectFit?: CSSProperties['objectFit']
}

const Source = styled.source`
  width: 100%;
  height: 100%;
`

const Img = styled.img<{objectFit: string}>`
  object-fit: ${props => props.objectFit};
  width: 100%;
  height: 100%;
`

export const WebpImage = ({ srcSet, src, alt, className = '', loadingStrategy = 'lazy', objectFit = 'cover' }: IImageProps) => (
  <picture className={className}>
    <Source srcSet={srcSet} type="image/webp" />
    <Img src={src} alt={alt} loading={loadingStrategy} objectFit={objectFit}/>
  </picture>
)