import { useTranslation } from 'next-i18next'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { SocialNetworkLinks } from '../../../utils/socialNetworkLinks'
import { Advice, FooterWrapper, LogoWrapper, MainWrapper, SpringsIntlInfo, SpringsWrapper, TermsWrapper } from './styles'

export const LandingPageFooter = () => {
  const { t: translate } = useTranslation(LocaleNamespaces.FOOTER)

  return (
    <FooterWrapper data-testid="footer-wrapper">    
      <MainWrapper data-testid="main-wrapper">
        <LogoWrapper href="/" data-testid="logo-wrapper">
          <img src="images/PersonoLPIcon.svg" alt="Better sleep, better life" loading="lazy"/>
        </LogoWrapper>

        <Advice data-testid="advice">
          {translate('landing-page-advice')}<a href={`mailto:${SocialNetworkLinks.EmailEn}`}>{translate('landing-page-email')}</a>
        </Advice>
      </MainWrapper>

      <SpringsWrapper data-testid="springs-wrapper">
        <TermsWrapper>
          <span className="terms">
            {translate('intl-terms-and-conditions')}
          </span>
          <div className="divider"/> 
          <span>
            {translate('intl-privacy-policy')}
          </span>
        </TermsWrapper>
        <SpringsIntlInfo data-testid="springs-intl-info">
          {translate('landing-page-springs-intl-info')}
        </SpringsIntlInfo>
      </SpringsWrapper>
    </FooterWrapper>
  )
}