import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import LeadStore from '../../stores/LeadStore/LeadStore'
import { InputButton, InputError, InputField, InputWrapper } from './styles'

interface SubscribeModalInputProps {
  store: LeadStore
  inputFieldClassName?: string
  inputButtonClassName?: string
  inputWrapperClassName?: string
  inputErrorClassName?: string
  openModalAfterSubscribe?: Function
  isprimaryColor?: boolean
}

export const SubscribeModalInput = observer((props: SubscribeModalInputProps) => {
  const { t: translate } = useTranslation(LocaleNamespaces.NOTIFY_ME_MODAL)

  const { store, inputButtonClassName, inputFieldClassName, inputWrapperClassName, inputErrorClassName, isprimaryColor } = props

  const feedbackErrorMessages = {
    '': '',
    'email-already-registered': translate('input-email-already-registered'),
    'invalid-email': translate('input-invalid-email'),
  }

  const onSubscribe = async () => {
    await store.sendEmail('en', false, 'LPI')
    if(store.successSendEmail && props.openModalAfterSubscribe != null) {
      props.openModalAfterSubscribe()
      store.resetValues()
    }
  }

  return (
    <InputWrapper data-testid="main-input-wrapper" className={inputWrapperClassName}>
      <InputError data-testid="error-message" className={inputErrorClassName}>
        {!store.isValidEmail && feedbackErrorMessages[store.feedbackMessageKey]}
      </InputError>

      <InputField
        name="email"
        autoComplete="on"
        data-testid="email-input"
        placeholder={translate('input-placeholder')}
        type="email"
        value={store.email}
        onChange={e => store.addEmail(e.target.value)}
        onKeyDown={e => {
          if (e.code === 'Enter') {
            onSubscribe()
          }
        }}
        autoFocus={false}
        isValidEmail={store.isValidEmail}
        className={inputFieldClassName}
      />

      <InputButton
        data-testid="input-button"
        value={translate('notify-me-button')}
        type="button"
        onClick={() => onSubscribe()}
        className={inputButtonClassName}
        isprimaryColor={isprimaryColor ?? true}
      />
    </InputWrapper>
  )
})