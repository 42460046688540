import { Spacer } from '../LandingPageHeader/styles'
import { KickstarterBannerDiv, KickstarterBannerP } from './style'

export const KickstarterBanner = (props: {text?: string}) => {
  const {text} = props

  return (
    text &&
      <>
        <Spacer desktopHeight={103} mobileHeight={76} />
        <KickstarterBannerDiv data-testid="kickstarter-main-div">
          <KickstarterBannerP data-testid="kickstarter-main-text">{text}</KickstarterBannerP>
        </KickstarterBannerDiv>
      </>
  )
}