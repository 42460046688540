import styled, { css } from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const Header = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #f7f7f7;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const Row = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75px;
  position: fixed;
  padding: 0 24px;
  z-index: 10;
  
  ${media.tabMUp} {
    height: 102px;
    padding: 0 50px 0 97px;
  }

  a {
    cursor: pointer;
  }
`

export const Spacer = styled.div<{ desktopHeight: number, mobileHeight: number }>`
  width: 100%;
  height: ${({ mobileHeight }) => mobileHeight}px;

  ${media.tabMUp} {
    height: ${({ desktopHeight }) => desktopHeight}px;
  }
`

export const LogoLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  
  .desktop {
    display: none;
    visibility: hidden;

    @media screen and (min-width: 880px) {
      display: initial;
      visibility: visible;
    }
  }

  .mobile {
    display: initial;
    visibility: visible;
    
    @media screen and (min-width: 880px) {
      display: none;
      visibility: hidden;
    }
  }
`

export const HeaderLink = styled.a<{ isActive: boolean }>`
  text-decoration: none;
  color: #4E98F1;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  margin-right: 11px;

  ${media.tabMUp} {
    font-size: 23px;
    margin-right: 72px;
  }

  &::before {
    content: "";
    position: absolute;
    margin-top: 16px;
    margin-left: ${props => props.isActive ? '0': '46px'};
    width: ${props => props.isActive ? '53px' : '0'};
    opacity: ${props => props.isActive ? '1' : '0'};
    height: 1px;
    background: #4E98F1;
    border-radius: 10px;
    transition: all 200ms ease-in-out;
    
    ${media.tabMUp} {
      margin-top: 26px;
      width: ${props => props.isActive ? '92px' : '0'};
    }
  }

  ${props => !props.isActive && css`
    &:hover::before {
      opacity: 1;
      width: 92px;
      margin-left: 0;
      transition: all 200ms ease-in-out;

      ${media.tabMUp} {
        width: 92px;
      }
    }
  `};
`