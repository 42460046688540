import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
`

export const Modal = styled.div<{success: boolean}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f7f7f7;
  display: flex;
  z-index: 100;
  flex-direction: row;
  justify-content: center;
  border-radius: 16px;
  font-family: 'Proxima Nova', sans-serif;
  box-shadow: 0px 1px 50px 0px rgba(0, 0, 0, 0.39);
  width: 90%;
  min-height: 50%;

  ${media.tabMUp} {
    margin: 10px;
    align-items: center;
    transform: translate(-50%, -50%);
    left: 50%;
    top: calc(50% - 10px);
    width: 90%;
    max-width: 1050px;
    max-height: 850px;
    border-radius: 16px;
    padding: 0;
    margin-left: unset;
    padding: 30px 30px 25px 30px;
  }
`

export const CloseIconDiv = styled.div`
  width: 23px;
  height: 23px;
  top: 19px;
  position: absolute;
  border-radius: 100px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 21px;
  right: unset;
  cursor: pointer;
  padding: 2px;
  z-index: 100;

  ${media.tabMUp} {
    background-color: #DADADA;
    width: 32px;
    height: 32px;
    top: 20px;
    right: 31px;
    left: unset;
    padding: 0;
  }
`

export const SubscribeAttempt = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  z-index: 10;
  border-radius: 16px;
  
  ${media.tabMUp} {
    background: unset;
    background-size: unset;
    background-position: unset;
    background-repeat: unset;
    flex-direction: row;
    margin: 0;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin: 10px 10px;
    
    ${media.tabMUp} {
      margin-top: auto;
      margin-right: 10px;
      margin-left: 10px;
    }
    
    @media only screen and (min-width: 990px) {
      margin-right: 38px;
      margin-left: 38px;
    }
  }

  .modal-title, .modal-text {
    color: #222;
    z-index: 100;
    text-align: center;
  }

  .modal-title { 
    background: linear-gradient(180deg, rgba(247, 247, 247, 0.00) 0%, #F7F7F7 60%);
    font-size: 24px;
    font-weight: 700;
    padding: 50px 20px 0;
    margin: 0px 0 18px;
    
    ${media.tabMUp} {
      margin: 0 0 37px;
    }

    @media only screen and (min-width: 990px) {
      font-size: 36px;
    }
    
    ${media.deskGUp} {
      font-size: 48px;
      line-height: 53px;
      font-weight: 700;
    }
  }

  .modal-text {
    font-size: 18px;
    margin: 0;
    padding: 0 20px;

    ${media.tabMUp} {
      font-size: 20px;
    }
    
    ${media.deskGUp} {
      font-size: 20px;
      font-weight: 400;
    }
  }

  .with-margin {
    margin-top: 20px;
    
    ${media.tabMUp} {
      margin-bottom: 100px;
    }
    
    @media only screen and (min-width: 988px) {
      margin-bottom: 100px;
    }

    @media only screen and (min-width: 1100px) {
      margin-bottom: 60px;
    }
  }

  .input-wrapper {
    width: 100%;
    max-width: 60vw;
    min-width: 270px;
    margin: 50px auto 0;
    
    ${media.tabMUp} {
      max-width: min(40vw, 700px);
      min-width: 440px;
      margin: 0px auto 0;
    }
  }
`

export const ModalImageWrapper = styled.div`
  background: url('images/lpi-modal-image.png');
  background-size: 100%;
  background-position: 0 -150px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 300px;
  position: absolute;
  border-radius: 16px;
  top: 0;

  @media only screen and (min-width: 376px){
    background-position: 0 -110px;
  }

  @media only screen and (min-width: 376px){
    background-position: 0 60%;
  }

  ${media.tabMUp} {
    position: unset;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 40% 0;
    min-width: 330px;
    max-width: 30vw;
    min-height: auto;
  }

  ${media.deskG} {
    background-position: 40% 60%;
  }
  
  @media only screen and (min-width: 1100px) {
    min-width: 522px;
    max-width: 30vw;
    min-height: auto;
  }
`

export const SubscribeSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 40vh;
  margin: 5%;

  .success-icon {
    width: 60px;
    height: 60px;
    background: url('/images/check.png');
    background-size: cover;
    margin-top: 125px;
    
    ${media.tabMUp} {
      margin-top: 0;
      width: 80px;
      height: 80px;
    }

    ${media.deskGUp} {
      width: 100px;
      height: 100px;
      margin-bottom: 16px;
    }
  }

  .success-text {
    color: #222;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    
    ${media.tabMUp} {
      font-size: 30px;
      line-height: 53px;
    }
    
    ${media.deskGUp} {
      font-size: 40px;
      line-height: 70px;
    }
  }
`
