import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const KickstarterBannerDiv = styled.div`
  background-color: #143748;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const KickstarterBannerP = styled.p`
  margin: 0;
  padding: 5px;
  font-family: 'Proxima Nova', sans-serif;
  color: #f7f7f7;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 11%;
  text-transform: uppercase;

  ${media.tabMUp} {
    font-size: 25px;
    line-height: 31px;
  }
`