import { captureException } from '@sentry/nextjs'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { getRemoteConfigValue, RemoteConfigKeys } from '../../../services/firebase/RemoteConfig'
import { CloseIcon } from '../../atoms/icons/CloseIcon'
import { SubscribeModalInput } from '../../atoms/SubscribeModalInput'
import LeadStore from '../../stores/LeadStore/LeadStore'
import {
  CloseIconDiv,
  Modal,
  ModalBackground,
  SubscribeAttempt,
  SubscribeSuccess
} from './SubscribeModalStyles'

interface ISubscribeModal {
  onCloseModal: Function,
  successSendEmail: boolean
}

const leadStore = new LeadStore()

export const SubscribeModal = observer((props: ISubscribeModal) => {
  const { t: translate } = useTranslation(LocaleNamespaces.NOTIFY_ME_MODAL)
  const { onCloseModal, successSendEmail } = props
  const [startCampaignDate, setStartCampaignDate] = useState<string>('')
  const [pillowPricePromo, setPillowPricePromo] = useState<string>('$79')
  const [pillowPriceRetail, setPillowPriceRetail] = useState<string>('$129')

  useEffect(() => {
    getRemoteConfigValues()
  }, [])

  const getRemoteConfigValues = async () => {
    try {
      const remoteStartCampaignDate = await getRemoteConfigValue<string>(RemoteConfigKeys.LPI_START_CAMPAIGN_DATE_POP_UP)
      if (remoteStartCampaignDate) {
        setStartCampaignDate(remoteStartCampaignDate)
      }
      const remotePromoPrice = await getRemoteConfigValue<string>(RemoteConfigKeys.LPI_PILLOW_PRICE_PROMO)
      const remoteRetailPrice = await getRemoteConfigValue<string>(RemoteConfigKeys.LPI_PILLOW_PRICE_RETAIL)
      if (remotePromoPrice && remoteRetailPrice) {
        setPillowPricePromo(remotePromoPrice)
        setPillowPriceRetail(remoteRetailPrice)
      }
    } catch (e) {
      captureException(e)
    }
  }

  return (
    <>
      <ModalBackground
        data-testid="modal-background"
        onClick={() => {
          onCloseModal()
          leadStore.resetValues()
        }}
      />

      <Modal data-testid="modal-wrapper" success={leadStore.successSendEmail}>
        <CloseIconDiv 
          onClick={() => {
            onCloseModal()
            leadStore.resetValues()
          }}
          data-testid="close-icon-div"
        >
          <CloseIcon />
        </CloseIconDiv>

        {(!leadStore.successSendEmail && !successSendEmail)
          ? <SubscribeAttempt>
            <div>
              <div className="text-wrapper">
                <p className="modal-title">{translate('title').replace('START_CAMPAIGN_DATE', startCampaignDate)}</p>
                <p className="modal-text">{translate('text-1').replace('PRICE_PROMO', pillowPricePromo).replace('PRICE_RETAIL', pillowPriceRetail)}</p>
                <p className="modal-text with-margin">{translate('text-2')}</p>
                <SubscribeModalInput
                  store={leadStore}
                  inputWrapperClassName="input-wrapper"
                />
              </div>
            </div>
          </SubscribeAttempt>
          : <SubscribeSuccess data-testid="success-component">
            <div className="success-icon" />
            <div className="success-text">{translate('success-text')}</div>
          </SubscribeSuccess>
        }
      </Modal>
    </>
  )
})
