import Head from 'next/head'
import { IPostMeta } from '../../services/interfaces/WordpressServiceDtos'
import { buildMeta } from '../../utils/buildMeta'
import { LandingPageFooter } from '../organisms/LandingPageFooter'
import { LandingPageHeader } from '../organisms/LandingPageHeader'

interface BaseProps {
  title: string
  meta?: IPostMeta[]
  children: React.ReactNode,
  noIndex?: boolean,
  toggleModal: Function
}

export const BaseEn = (props: BaseProps) => {
  const noIndex: boolean = props.noIndex ?? false
  const { toggleModal, title, meta, children } = props

  return (
    <>
      <Head>
        <title>{title}</title>

        {noIndex
          ? <>
            <meta name="robots" content="noindex" />
            <meta name="robots" content="nofollow" />
          </>
          : <>
            {meta && buildMeta(meta)}
          </>
        }
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>

        <style>
          {
            `
              html: {
                font-family: 'Proxima Nova', sans-serif;
              }
              body: {
                font-family: 'Proxima Nova', sans-serif;
              }
              * {
                font-family: 'Proxima Nova', sans-serif;
              }
            `
          }
        </style>
      </Head>
      
      <LandingPageHeader onClickButton={toggleModal} />
      {children}
      <LandingPageFooter />
    </>
  )
}