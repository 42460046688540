import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const FooterWrapper = styled.footer`
  background-color: #222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1440px;

  ${media.tabMUp} {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 90px min(110px, 10%) 0;
  }
`

export const LogoWrapper = styled.a`
  margin: 27px auto 34px;
  padding: 0;
  cursor: pointer;

  ${media.tabMUp} {
    margin: 0 min(20vw, 200px) 0 0;
  }

  img {
    max-width: 155px;
    max-height: 30px;

    ${media.tabMUp} {
      max-width: min(27vw, 305px);
      max-height: 80px;
    }
  }
`

export const Advice = styled.p`
  color: #fff;
  font-size: 10px;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 400;
  line-height: 120%;
  width: 100%;
  max-width: 60vw;
  text-align: center;

  ${media.tabMUp} {
    text-align: left;
    font-size: 25px;
    min-width: 200px;
    width: max-content;
    max-width: 470px;
  }

  a {
    color: #fff;
    border-bottom: 1px solid #fff;
    text-decoration: none;
    font-weight: 700;
  }
`

export const SpringsWrapper = styled.div`
  margin: 33px 0 18px;
  padding: 0;
  text-align: center;

  ${media.tabMUp} {
    margin: 75px 0 31px;
  }
`

export const SpringsIntlInfo = styled.p`
  color: #848484;
  font-size: 7px;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 400;

  ${media.tabMUp} {
    font-size: 25px;
  }

  .divider {
    ${media.tabMUp} {
      min-width: 1px;
      min-height: 15px;
      width: 1px;
      height: 15px;
      background-color: red;
    }
  }
`

export const TermsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  span {
    color: #848484;
    font-size: 7px;
    font-weight: 400;
    text-decoration: none;
    font-family: 'Proxima Nova', sans-serif;

    ${media.tabMUp} {
      font-size: 25px;
    }
  }

  .terms {
    margin-left: -15px;
    
    ${media.tabMUp} {
      margin-left: -25px;
    }
  }

  .divider {
    min-width: 1px;
    min-height: 8px;
    background-color: #848484;
    margin: 0 12px;

    ${media.tabMUp} {
      margin: 0 24px;
    }
  }
`